import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Copy,
  ExternalLink,
  Globe,
  Calendar,
  MousePointerClick,
} from "lucide-react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { timeAgo } from "../helpers/datetime/timeAgo";
import { urlService } from "../services/urlService";

const UrlDetails = () => {
  const { shortCode } = useParams();
  const [analytics, setAnalytics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const groupBrowserData = (browserData) => {
    const browserMapping = {
      Chrome: /Chrome|chrome/i,
      Firefox: /Firefox|firefox/i,
      Safari: /Safari|safari/i,
      Edge: /Edge|edge/i,
      "Mobile Chrome": /Mobile Chrome|mobile chrome/i,
      "Mobile Safari": /Mobile Safari|mobile safari/i,
      "Mobile Firefox": /Mobile Firefox|mobile firefox/i,
    };

    const groupedData = {};

    Object.entries(browserData).forEach(([browser, count]) => {
      let matched = false;
      for (const [groupName, pattern] of Object.entries(browserMapping)) {
        if (pattern.test(browser)) {
          groupedData[groupName] = (groupedData[groupName] || 0) + count;
          matched = true;
          break;
        }
      }
      if (!matched) {
        groupedData["Other"] = (groupedData["Other"] || 0) + count;
      }
    });

    return Object.entries(groupedData).map(([browser, count]) => ({
      name: browser,
      clicks: count,
    }));
  };

  const groupOSData = (osData) => {
    const osMapping = {
      Windows: /Windows/i,
      macOS: /macOS/i,
      iOS: /iOS/i,
      Android: /Android/i,
      Linux: /Linux/i,
    };

    const groupedData = {};

    Object.entries(osData).forEach(([os, count]) => {
      let matched = false;
      for (const [groupName, pattern] of Object.entries(osMapping)) {
        if (pattern.test(os)) {
          groupedData[groupName] = (groupedData[groupName] || 0) + count;
          matched = true;
          break;
        }
      }
      if (!matched) {
        groupedData["Other"] = (groupedData["Other"] || 0) + count;
      }
    });

    return Object.entries(groupedData).map(([os, count]) => ({
      name: os,
      clicks: count,
    }));
  };

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        setLoading(true);
        const data = await urlService.getUrlAnalytics(shortCode);
        setAnalytics(data);
      } catch (err) {
        setError(err.message);
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (shortCode) {
      fetchAnalytics();
    }
  }, [shortCode]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard!");
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-white p-6">
        <div className="flex items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-cyan-600"></div>
          <p className="ml-4 text-gray-600">Loading analytics...</p>
        </div>
      </div>
    );
  }

  if (error || !analytics) {
    return (
      <div className="min-h-screen bg-white p-6">
        <div className="text-center text-red-600">
          <p>{error || "No analytics data available"}</p>
        </div>
      </div>
    );
  }

  // Prepare grouped data for charts
  const browserData = groupBrowserData(analytics.clicksByBrowser);
  const osData = groupOSData(analytics.clicksByOS);
  const countryData = Object.entries(analytics.clicksByCountry).map(
    ([country, count]) => ({
      name: country || "Unknown",
      clicks: count,
    })
  );

  const CHART_COLORS = {
    browser: ["#0891b2", "#0ea5e9", "#38bdf8", "#7dd3fc", "#bae6fd"],
    os: ["#059669", "#10b981", "#34d399", "#6ee7b7", "#a7f3d0"],
    country: ["#d97706", "#f59e0b", "#fbbf24", "#fcd34d", "#fde68a"],
  };

  const renderPieChart = (data, title, colorSet) => (
    <div className="bg-white rounded-lg shadow-md p-6 border border-gray-200">
      <h2 className="text-xl font-semibold text-gray-900 mb-6">{title}</h2>
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data}
              dataKey="clicks"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              paddingAngle={5}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colorSet[index % colorSet.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-white">
      <div className="w-full max-w-7xl mx-auto p-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">
          Analytics Overview
        </h1>
        <p className="text-xl text-gray-600 mb-12">
          Track and analyze your link performance
        </p>

        {/* URL Cards */}
        <div className="grid grid-cols-1 gap-6 mb-12">
          <div className="bg-white rounded-lg shadow-md p-6 border border-gray-200">
            <div className="flex flex-col space-y-4">
              <div className="flex items-center justify-between">
                <div>
                  <label className="text-sm text-gray-500">Short URL</label>
                  <div className="flex items-center gap-2 mt-1">
                    <a
                      href={analytics.urlDetails.fullShortUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-cyan-600 hover:text-cyan-700"
                    >
                      {analytics.urlDetails.fullShortUrl}
                    </a>
                    <button
                      onClick={() =>
                        copyToClipboard(analytics.urlDetails.fullShortUrl)
                      }
                      className="p-1.5 rounded-md hover:bg-gray-100 text-gray-500"
                      title="Copy URL"
                    >
                      <Copy size={16} />
                    </button>
                    <a
                      href={analytics.urlDetails.fullShortUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="p-1.5 rounded-md hover:bg-gray-100 text-gray-500"
                    >
                      <ExternalLink size={16} />
                    </a>
                  </div>
                </div>
                <div className="text-right">
                  <label className="text-sm text-gray-500">Original URL</label>
                  <div className="flex items-center gap-2 mt-1">
                    <span className="text-gray-600 truncate max-w-md">
                      {analytics.urlDetails.originalUrl}
                    </span>
                    <button
                      onClick={() =>
                        copyToClipboard(analytics.urlDetails.originalUrl)
                      }
                      className="p-1.5 rounded-md hover:bg-gray-100 text-gray-500"
                    >
                      <Copy size={16} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Stats Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
          <div className="bg-white rounded-lg shadow-md p-6 border border-gray-200">
            <div className="flex items-center">
              <MousePointerClick className="w-12 h-12 text-cyan-600" />
              <div className="ml-4">
                <p className="text-sm text-gray-500">Total Clicks</p>
                <p className="text-2xl font-semibold text-gray-900">
                  {analytics.totalClicks.toLocaleString()}
                </p>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-md p-6 border border-gray-200">
            <div className="flex items-center">
              <Calendar className="w-12 h-12 text-cyan-600" />
              <div className="ml-4">
                <p className="text-sm text-gray-500">Created</p>
                <p className="text-2xl font-semibold text-gray-900">
                  {timeAgo(analytics.urlDetails.createdAt)}
                </p>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-md p-6 border border-gray-200">
            <div className="flex items-center">
              <Globe className="w-12 h-12 text-cyan-600" />
              <div className="ml-4">
                <p className="text-sm text-gray-500">Custom Domain</p>
                <p className="text-2xl font-semibold text-gray-900">
                  {analytics.urlDetails.customDomain}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Charts Row Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          {renderPieChart(browserData, "Browsers", CHART_COLORS.browser)}
          {renderPieChart(osData, "Operating Systems", CHART_COLORS.os)}
          {renderPieChart(countryData, "Countries", CHART_COLORS.country)}
        </div>

        {/* Recent Clicks Table */}
        <div className="bg-white rounded-lg shadow-md border border-gray-200">
          <div className="p-6 border-b border-gray-200">
            <h2 className="text-xl font-semibold text-gray-900">
              Recent Clicks
            </h2>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Time
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Location
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Browser
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Operating System
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    IP Address
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {analytics.recentClicks.map((click, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {new Date(click.timestamp).toLocaleString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {click.location?.city && click.location?.country
                        ? `${click.location.city}, ${click.location.country}`
                        : "Unknown location"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {click.device?.browser || "Unknown"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {click.device?.os || "Unknown"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {click.ip || "Unknown"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UrlDetails;

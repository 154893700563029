import React, { useEffect, useState } from "react";
import {
  QrCode,
  Share2,
  Copy,
  Trash2,
  Search,
  Pencil,
  Replace,
  Save,
  Ban,
  Eye,
  Link2,
} from "lucide-react";
import { timeAgo } from "../helpers/datetime/timeAgo";
import { toast } from "react-toastify";
import { urlService } from "../services/urlService";
import ShareDropdown from "./helpers/ShareDropdown";
import { useNavigate } from "react-router-dom";
import { TagBadge, TagInput } from "./TagBadge";
import QRCodeModal from "./QRCodeModal";
import TagFilter from "./TagFilter";
import FloatingUrlButton from "./FloatingUrlButton";

const UrlList = () => {
  const navigate = useNavigate();
  const [urls, setUrls] = useState([]);
  const [filteredUrls, setFilteredUrls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [editType, setEditType] = useState(null);
  const [editValue, setEditValue] = useState("");
  const [shareOpen, setShareOpen] = useState(null);
  const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [sortOption, setSortOption] = useState("dateDesc"); // New state for sorting
  const [showUrlForm, setShowUrlForm] = useState(false); // New state for URL form
  const [newUrlData, setNewUrlData] = useState({
    // New state for URL form data
    longUrl: "",
    customAlias: "",
    customDomain: "https://shrtly.net",
  });

  useEffect(() => {
    fetchUrls();
  }, []);

  // Filter and sort URLs
  useEffect(() => {
    let filtered = [...urls];

    // Apply tag filtering
    if (selectedTags.length > 0) {
      filtered = filtered.filter((url) =>
        selectedTags.every((selectedTag) =>
          url.tags?.some((urlTag) => urlTag.name === selectedTag.name)
        )
      );
    }

    // Apply search query filtering
    if (searchQuery) {
      filtered = filtered.filter(
        (url) =>
          url.originalUrl.toLowerCase().includes(searchQuery.toLowerCase()) ||
          url.shortCode.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Apply sorting
    filtered.sort((a, b) => {
      switch (sortOption) {
        case "dateAsc":
          return new Date(a.createdAt) - new Date(b.createdAt);
        case "dateDesc":
          return new Date(b.createdAt) - new Date(a.createdAt);
        case "clicksAsc":
          return a.totalClicks - b.totalClicks;
        case "clicksDesc":
          return b.totalClicks - a.totalClicks;
        default:
          return 0;
      }
    });

    setFilteredUrls(filtered);
  }, [urls, selectedTags, searchQuery, sortOption]);

  const handleCreateUrl = async (e) => {
    e.preventDefault();
    try {
      const response = await urlService.createShortUrl(newUrlData);
      toast.success("URL shortened successfully!");
      setShowUrlForm(false);
      setNewUrlData({
        longUrl: "",
        customAlias: "",
        customDomain: "https://shrtly.net",
      });
      fetchUrls(); // Refresh the list
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleTagFilter = (tags) => {
    setSelectedTags(tags);
  };

  const fetchUrls = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await urlService.getAllUrls();
      setUrls(data);
      setFilteredUrls(data);
    } catch (err) {
      setError(err.message);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleQrCodeClick = (url) => {
    setSelectedUrl(`${url.customDomain}/${url.shortCode}`);
    setQrCodeModalOpen(true);
  };

  const deleteUrl = async (id) => {
    try {
      setLoading(true);
      await urlService.deleteUrl(id);
      await fetchUrls();
      toast.success("URL deleted successfully!");
    } catch (err) {
      toast.error(err.message);
    }
  };

  const copyUrl = (url) => {
    navigator.clipboard.writeText(url);
    toast.success("URL copied successfully!");
  };

  const startEditing = (url, type) => {
    setEditingId(url._id);
    setEditType(type);
    setEditValue(type === "shortCode" ? url.shortCode : url.originalUrl);
  };

  const cancelEditing = () => {
    setEditingId(null);
    setEditType(null);
    setEditValue("");
  };

  const handleUpdate = async (id) => {
    try {
      setLoading(true);
      const updateData =
        editType === "shortCode"
          ? { shortCode: editValue }
          : { originalUrl: editValue };

      await urlService.updateUrl(id, updateData);
      await fetchUrls();
      cancelEditing();
      toast.success(`URL ${editType} updated successfully!`);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 p-8">
        <div className="flex items-center justify-center">
          <div className="animate-spin h-12 w-12 border-4 border-blue-500 border-t-transparent rounded-full" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 p-8 text-center">
        <div className="max-w-2xl mx-auto bg-red-500/10 p-6 rounded-lg border border-red-500/20">
          <p className="text-red-400 mb-4">{error}</p>
          <button
            onClick={fetchUrls}
            className="px-6 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 p-8">
      <div className="max-w-7xl mx-auto space-y-6">
        <div className="flex flex-col md:flex-row justify-between items-start gap-4">
          <h1 className="text-3xl font-bold text-white">Your URLs</h1>
          <div className="flex flex-col md:flex-row gap-4 w-full md:w-auto">
            <select
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
              className="bg-white/5 text-white rounded-lg py-2 px-4 border border-white/10 focus:outline-none focus:ring-2 focus:ring-blue-500 [&>option]:bg-gray-800 [&>option]:text-white"
            >
              <option value="dateDesc">Newest First</option>
              <option value="dateAsc">Oldest First</option>
              <option value="clicksDesc">Most Clicks</option>
              <option value="clicksAsc">Least Clicks</option>
            </select>
            <div className="w-full md:w-96">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search URLs..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full bg-white/5 text-white rounded-full py-3 px-6 pl-12 border border-white/10 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <Search
                  className="absolute left-4 top-3.5 text-white/60"
                  size={20}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Tag Filter */}
        <TagFilter
          onFilterChange={handleTagFilter}
          className="bg-white/5 p-4 rounded-lg"
        />

        {/* URL Cards Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredUrls.map((url) => (
            <div
              key={url._id}
              className="bg-white/5 rounded-lg p-6 hover:bg-white/10 transition-all duration-300"
            >
              {/* URL Card Content */}
              <div className="flex items-start gap-4 mb-4">
                <div className="w-12 h-12 rounded-full bg-gradient-to-br from-blue-500 to-purple-500 flex items-center justify-center text-white text-xl font-bold">
                  {url.customDomain.charAt(0).toUpperCase()}
                </div>
                <div className="flex-1 min-w-0">
                  <h3 className="text-white font-medium truncate">
                    {editingId === url._id && editType === "shortCode" ? (
                      <div className="flex items-center gap-2">
                        <input
                          type="text"
                          value={editValue}
                          onChange={(e) => setEditValue(e.target.value)}
                          className="flex-1 bg-black/20 text-white rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                          autoFocus
                        />
                        <button
                          onClick={() => handleUpdate(url._id)}
                          className="p-1 text-green-400 hover:text-green-300"
                          title="Save"
                        >
                          <Save size={16} />
                        </button>
                        <button
                          onClick={cancelEditing}
                          className="p-1 text-red-400 hover:text-red-300"
                          title="Cancel"
                        >
                          <Ban size={16} />
                        </button>
                      </div>
                    ) : (
                      `${url.customDomain}/${url.shortCode}`
                    )}
                  </h3>
                  <p className="text-white/60 text-sm truncate mt-1">
                    {editingId === url._id && editType === "originalUrl" ? (
                      <div className="flex items-center gap-2">
                        <input
                          type="text"
                          value={editValue}
                          onChange={(e) => setEditValue(e.target.value)}
                          className="flex-1 bg-black/20 text-white rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                          autoFocus
                        />
                        <button
                          onClick={() => handleUpdate(url._id)}
                          className="p-1 text-green-400 hover:text-green-300"
                          title="Save"
                        >
                          <Save size={16} />
                        </button>
                        <button
                          onClick={cancelEditing}
                          className="p-1 text-red-400 hover:text-red-300"
                          title="Cancel"
                        >
                          <Ban size={16} />
                        </button>
                      </div>
                    ) : (
                      url.originalUrl
                    )}
                  </p>
                  <div className="flex items-center gap-2 mt-2">
                    <span className="text-white/60 text-sm">
                      {url.totalClicks} clicks
                    </span>
                    <span className="text-white/40">•</span>
                    <span className="text-white/60 text-sm">
                      {timeAgo(url.createdAt)}
                    </span>
                  </div>
                </div>
              </div>

              {/* Tags */}
              <div className="flex flex-wrap gap-2 mb-4">
                {url.tags?.map((tag) => (
                  <TagBadge
                    key={tag.name}
                    tag={tag}
                    onRemove={async () => {
                      try {
                        await urlService.removeTag(url._id, tag.name);
                        fetchUrls();
                      } catch (error) {
                        toast.error(error.message);
                      }
                    }}
                  />
                ))}
                <TagInput
                  onAddTag={async (newTag) => {
                    try {
                      await urlService.addTags(url._id, [newTag]);
                      fetchUrls();
                    } catch (error) {
                      toast.error(error.message);
                    }
                  }}
                />
              </div>

              {/* Action Buttons */}
              <div className="flex flex-wrap gap-2">
                <button
                  onClick={() => startEditing(url, "shortCode")}
                  className="p-2 rounded-lg bg-white/10 hover:bg-white/20 text-white transition-colors"
                  title="Edit Short Code"
                >
                  <Pencil size={18} />
                </button>
                <button
                  onClick={() => startEditing(url, "originalUrl")}
                  className="p-2 rounded-lg bg-white/10 hover:bg-white/20 text-white transition-colors"
                  title="Edit Original URL"
                >
                  <Replace size={18} />
                </button>
                <button
                  onClick={() => handleQrCodeClick(url)}
                  className="p-2 rounded-lg bg-white/10 hover:bg-white/20 text-white transition-colors"
                  title="Generate QR Code"
                >
                  <QrCode size={18} />
                </button>
                <ShareDropdown
                  url={url}
                  isOpen={shareOpen === url._id}
                  setIsOpen={(open) => setShareOpen(open ? url._id : null)}
                />
                <button
                  onClick={() =>
                    copyUrl(`${url.customDomain}/${url.shortCode}`)
                  }
                  className="p-2 rounded-lg bg-white/10 hover:bg-white/20 text-white transition-colors"
                  title="Copy URL"
                >
                  <Copy size={18} />
                </button>
                <button
                  onClick={() => deleteUrl(url._id)}
                  className="p-2 rounded-lg bg-white/10 hover:bg-white/20 text-white transition-colors"
                  title="Delete"
                >
                  <Trash2 size={18} />
                </button>
                <button
                  onClick={() => navigate(`/details/${url.shortCode}`)}
                  className="p-2 rounded-lg bg-white/10 hover:bg-white/20 text-white transition-colors"
                  title="View Analytics"
                >
                  <Eye size={18} />
                </button>
              </div>
            </div>
          ))}
        </div>

        {filteredUrls.length === 0 && (
          <div className="text-center py-12">
            <p className="text-white/60">No URLs found</p>
          </div>
        )}
      </div>

      {/* QR Code Modal */}
      <QRCodeModal
        isOpen={qrCodeModalOpen}
        onClose={() => setQrCodeModalOpen(false)}
        url={selectedUrl}
      />

      {/* Floating Action Button */}
      <FloatingUrlButton onUrlCreated={fetchUrls} />
    </div>
  );
};

export default UrlList;

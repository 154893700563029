// src/components/TagBadge.js
import React, { useState } from "react";
import PropTypes from "prop-types";
import { X, Plus, Tag as TagIcon } from "lucide-react";

// Define the tag shape that will be used in both components
const tagShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
});

const TagBadge = ({ tag, onRemove, className = "" }) => (
  <span
    className={`inline-flex items-center gap-1 px-2 py-1 rounded-full text-sm ${className}`}
    style={{ backgroundColor: `${tag.color}20`, color: tag.color }}
  >
    <TagIcon size={14} />
    {tag.name}
    {onRemove && (
      <button
        onClick={(e) => {
          e.stopPropagation();
          onRemove(tag);
        }}
        className="hover:bg-white/20 rounded-full p-0.5"
      >
        <X size={14} />
      </button>
    )}
  </span>
);

TagBadge.propTypes = {
  tag: tagShape.isRequired,
  onRemove: PropTypes.func,
  className: PropTypes.string,
};

const TagInput = ({ onAddTag, existingTags = [] }) => {
  const [isAdding, setIsAdding] = useState(false);
  const [tagName, setTagName] = useState("");
  const [tagColor, setTagColor] = useState("#3B82F6");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (tagName.trim()) {
      onAddTag({ name: tagName.trim(), color: tagColor });
      setTagName("");
      setTagColor("#3B82F6");
      setIsAdding(false);
    }
  };

  if (!isAdding) {
    return (
      <button
        onClick={() => setIsAdding(true)}
        className="inline-flex items-center gap-1 text-sm text-white/60 hover:text-white"
      >
        <Plus size={16} />
        Add Tag
      </button>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="flex items-center gap-2">
      <input
        type="text"
        value={tagName}
        onChange={(e) => setTagName(e.target.value)}
        placeholder="Tag name"
        className="bg-white/5 text-white border border-white/10 rounded px-2 py-1 text-sm focus:outline-none focus:ring-2 focus:ring-purple-500"
        autoFocus
      />
      <input
        type="color"
        value={tagColor}
        onChange={(e) => setTagColor(e.target.value)}
        className="w-8 h-8 rounded cursor-pointer"
      />
      <button
        type="submit"
        className="px-2 py-1 bg-purple-500 text-white rounded text-sm hover:bg-purple-600"
      >
        Add
      </button>
      <button
        type="button"
        onClick={() => setIsAdding(false)}
        className="p-1 text-white/60 hover:text-white"
      >
        <X size={16} />
      </button>
    </form>
  );
};

TagInput.propTypes = {
  onAddTag: PropTypes.func.isRequired,
  existingTags: PropTypes.arrayOf(tagShape),
};

TagInput.defaultProps = {
  existingTags: [],
};

// Export both components and the tag shape for reuse
export { TagBadge, TagInput, tagShape };

import React from "react";
import PropTypes from "prop-types";
import { Eye, X, Mail, Loader2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { authService } from "../services/authService";
import { useAuth } from "./context/AuthContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GoogleAuthButton from "./GoogleAuthButton";

// Custom Alert Component
const Alert = ({ variant = "default", children }) => {
  const bgColor =
    variant === "destructive" ? "bg-red-500/10" : "bg-blue-500/10";
  const textColor =
    variant === "destructive" ? "text-red-400" : "text-blue-400";

  return (
    <div
      className={`p-4 ${bgColor} ${textColor} rounded-lg border border-white/10`}
    >
      {children}
    </div>
  );
};

Alert.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const AuthLayout = ({ children }) => (
  <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 flex flex-col items-center justify-center p-4">
    <div className="w-full max-w-md bg-white/5 rounded-lg border border-white/10 p-8 relative">
      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold text-white mb-4">SHRLY</h1>
        <h2 className="text-xl text-white/60">Welcome to Shrtly</h2>
      </div>
      {children}
    </div>
  </div>
);

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

const SignIn = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [showPassword, setShowPassword] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      const response = await authService.login(email, password);

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || "Login failed");
      }

      const data = await response.json();
      login(data);
      navigate("/urls");
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      const response = await authService.googleAuth(
        credentialResponse.credential
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Google authentication failed");
      }

      if (data.token) {
        login(data);
        navigate("/urls");
      } else {
        throw new Error("No authentication token received");
      }
    } catch (error) {
      console.error("Google auth error:", error);
      toast.error(error.message);
    }
  };

  const handleGoogleError = () => {
    toast.error("Google authentication failed");
  };

  return (
    <AuthLayout>
      <GoogleAuthButton
        onSuccess={handleGoogleSuccess}
        onError={handleGoogleError}
      />

      <div className="relative my-6">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-white/10"></div>
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="px-2 bg-transparent text-white/60">
            Or continue with
          </span>
        </div>
      </div>

      <form className="space-y-6" onSubmit={handleSubmit}>
        {error && <Alert variant="destructive">{error}</Alert>}

        <div>
          <label className="block text-white/80 mb-2">Email</label>
          <div className="relative">
            <Mail
              className="absolute left-3 top-1/2 -translate-y-1/2 text-white/40"
              size={20}
            />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full pl-10 p-2 bg-white/5 border border-white/10 rounded text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
        </div>

        <div>
          <label className="block text-white/80 mb-2">Password</label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 bg-white/5 border border-white/10 rounded text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-2 top-1/2 -translate-y-1/2 text-white/40 hover:text-white/60"
            >
              <Eye size={20} />
            </button>
          </div>
        </div>

        <button
          type="submit"
          disabled={loading}
          className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 flex items-center justify-center disabled:bg-blue-500/50 transition-colors"
        >
          {loading ? (
            <Loader2 className="animate-spin" size={20} />
          ) : (
            <>
              <span className="mr-2">→</span> Sign In
            </>
          )}
        </button>
      </form>

      <p className="text-center mt-6 text-white/60">
        Don&apos;t have an account?{" "}
        <button
          onClick={() => navigate("/signup")}
          className="text-blue-400 hover:text-blue-300"
        >
          Sign Up
        </button>
      </p>
    </AuthLayout>
  );
};

const SignUp = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [showPassword, setShowPassword] = React.useState(false);
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
  });
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      const response = await authService.signup(
        formData.email,
        formData.password
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Registration failed");
      }

      toast.success(
        "Registration successful! Please check your email for verification link.",
        { duration: 5000 }
      );

      setTimeout(() => {
        navigate("/verify-pending/" + formData.email);
      }, 5000);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      const response = await authService.googleAuth(
        credentialResponse.credential
      );
      const data = await response.json();

      if (response.ok) {
        login(data.token, data);
        navigate("/urls");
      } else {
        throw new Error(data.error || "Google authentication failed");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleGoogleError = () => {
    toast.error("Google authentication failed");
  };

  return (
    <AuthLayout>
      <GoogleAuthButton
        onSuccess={handleGoogleSuccess}
        onError={handleGoogleError}
      />

      <div className="relative my-6">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-white/10"></div>
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="px-2 bg-transparent text-white/60">
            Or continue with
          </span>
        </div>
      </div>

      <form className="space-y-6" onSubmit={handleSubmit}>
        {error && <Alert variant="destructive">{error}</Alert>}

        <div>
          <label className="block text-white/80 mb-2">Email</label>
          <div className="relative">
            <Mail
              className="absolute left-3 top-1/2 -translate-y-1/2 text-white/40"
              size={20}
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full pl-10 p-2 bg-white/5 border border-white/10 rounded text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
        </div>

        <div>
          <label className="block text-white/80 mb-2">Password</label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full p-2 bg-white/5 border border-white/10 rounded text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              minLength={6}
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-2 top-1/2 -translate-y-1/2 text-white/40 hover:text-white/60"
            >
              <Eye size={20} />
            </button>
          </div>
          <p className="text-sm text-white/60 mt-1">
            Must be at least 6 characters long
          </p>
        </div>

        <button
          type="submit"
          disabled={loading}
          className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 flex items-center justify-center disabled:bg-blue-500/50 transition-colors"
        >
          {loading ? (
            <Loader2 className="animate-spin" size={20} />
          ) : (
            <>
              <span className="mr-2">→</span> Create Account
            </>
          )}
        </button>
      </form>

      <p className="text-center mt-6 text-white/60">
        Already have an account?{" "}
        <button
          onClick={() => navigate("/signin")}
          className="text-blue-400 hover:text-blue-300"
        >
          Sign In
        </button>
      </p>
    </AuthLayout>
  );
};

export { SignIn, SignUp };

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  User,
  Package,
  Link2,
  BarChart2,
  Calendar,
  ArrowUpRight,
  History,
  ArrowRight,
  Shield,
  Clock,
  Globe,
} from "lucide-react";
import PropTypes from "prop-types";
import { urlService } from "../services/urlService";
import { toast } from "react-toastify";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const StatCard = ({ icon: Icon, title, value }) => (
  <div className="bg-white/5 rounded-lg p-6 hover:bg-white/10 transition-all duration-300">
    <div className="flex items-center gap-3 mb-3">
      <Icon size={24} className="text-blue-400" />
      <h3 className="font-medium text-white">{title}</h3>
    </div>
    <p className="text-3xl font-semibold text-blue-400">{value}</p>
  </div>
);

StatCard.propTypes = {
  icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

const PlanFeature = ({ icon: Icon, title, description }) => (
  <div className="flex items-start gap-3 bg-white/5 p-4 rounded-lg">
    <Icon size={20} className="text-blue-400 flex-shrink-0 mt-1" />
    <div>
      <h4 className="font-medium text-white">{title}</h4>
      <p className="text-white/60 text-sm">{description}</p>
    </div>
  </div>
);

PlanFeature.propTypes = {
  icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const PlanDetails = ({ planInfo, planHistory }) => {
  const navigate = useNavigate();

  const stats = [
    {
      icon: Package,
      title: "Current Plan",
      value: planInfo?.currentPlan || "FREE",
    },
    {
      icon: Link2,
      title: "Links Used",
      value: `${planInfo?.linkCount || 0} / ${
        planInfo?.limits?.maxLinks || "∞"
      }`,
    },
    {
      icon: BarChart2,
      title: "Monthly Clicks",
      value: `${planInfo?.monthlyClickCount || 0} / ${
        planInfo?.limits?.maxClicksPerMonth || "∞"
      }`,
    },
  ];

  const planFeatures = [
    {
      icon: Clock,
      title: "Link Expiration",
      description: `Links expire after ${
        planInfo?.limits?.linkExpirationDays || 14
      } days`,
    },
    {
      icon: Globe,
      title: "Custom Domains",
      description: planInfo?.limits?.customDomains
        ? "Custom domain support enabled"
        : "Custom domains not available",
    },
    {
      icon: Shield,
      title: "Analytics Level",
      description: `${
        planInfo?.limits?.analytics || "Basic"
      } analytics tracking`,
    },
  ];

  return (
    <div className="space-y-8">
      <div className="bg-white/5 rounded-xl p-8 border border-white/10">
        <div className="flex items-center gap-4 mb-8">
          <div className="w-16 h-16 bg-blue-500/10 rounded-lg flex items-center justify-center">
            <Package className="text-blue-400" size={32} />
          </div>
          <div>
            <h2 className="text-2xl font-semibold text-white">Plan Details</h2>
            <p className="text-white/60">Your current plan and usage</p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          {stats.map((stat, index) => (
            <StatCard key={index} {...stat} />
          ))}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {planFeatures.map((feature, index) => (
            <PlanFeature key={index} {...feature} />
          ))}
        </div>
      </div>

      {/* Usage Trends */}
      <div className="bg-white/5 rounded-xl p-8 border border-white/10">
        <div className="flex items-center justify-between mb-8">
          <div>
            <h2 className="text-2xl font-semibold text-white">Usage Trends</h2>
            <p className="text-white/60">Click analytics over time</p>
          </div>
        </div>
        <div className="h-96">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={Object.entries(planInfo?.monthlyClicks || {}).map(
                ([date, clicks]) => ({
                  date,
                  clicks,
                })
              )}
            >
              <XAxis dataKey="date" stroke="#60A5FA" />
              <YAxis stroke="#60A5FA" />
              <Tooltip
                contentStyle={{
                  backgroundColor: "rgba(17, 24, 39, 0.8)",
                  border: "none",
                  color: "#fff",
                }}
              />
              <Line
                type="monotone"
                dataKey="clicks"
                stroke="#60A5FA"
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Plan History */}
      <div className="bg-white/5 rounded-xl p-8 border border-white/10">
        <div className="flex items-center justify-between mb-8">
          <div>
            <h2 className="text-2xl font-semibold text-white">Plan History</h2>
            <p className="text-white/60">Your subscription timeline</p>
          </div>
        </div>
        <div className="space-y-4">
          {planHistory?.planHistory?.map((history, index) => (
            <div key={index} className="bg-white/5 rounded-lg p-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <Calendar size={24} className="text-blue-400" />
                  <div>
                    <span className="text-white font-medium">
                      {history.toPlan}
                    </span>
                    <p className="text-white/60 text-sm">{history.notes}</p>
                  </div>
                </div>
                <span className="text-white/60">
                  {new Date(history.changeDate).toLocaleDateString()}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Upgrade Prompt */}
      <div className="bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-xl p-8 border border-blue-500/20">
        <div className="flex items-center justify-between">
          <div>
            <h2 className="text-2xl font-semibold text-white">
              Upgrade from {planInfo?.currentPlan || "FREE"}
            </h2>
            <p className="text-white/60 text-lg mt-2">
              Get more features and higher limits with our premium plans
            </p>
          </div>
          <button
            onClick={() => navigate("/plans")}
            className="flex items-center gap-2 bg-blue-500 text-white px-8 py-3 rounded-lg hover:bg-blue-600 transition-colors text-lg font-medium"
          >
            View Plans
            <ArrowUpRight size={24} />
          </button>
        </div>
      </div>
    </div>
  );
};

PlanDetails.propTypes = {
  planInfo: PropTypes.shape({
    currentPlan: PropTypes.string,
    linkCount: PropTypes.number,
    monthlyClickCount: PropTypes.number,
    monthlyClicks: PropTypes.object,
    limits: PropTypes.shape({
      maxLinks: PropTypes.number,
      remainingLinks: PropTypes.number,
      maxClicksPerMonth: PropTypes.number,
      linkExpirationDays: PropTypes.number,
      customDomains: PropTypes.bool,
      analytics: PropTypes.string,
    }),
  }).isRequired,
  planHistory: PropTypes.shape({
    currentPlan: PropTypes.string,
    planStartDate: PropTypes.string,
    planHistory: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        fromPlan: PropTypes.string,
        toPlan: PropTypes.string,
        reason: PropTypes.string,
        notes: PropTypes.string,
        performedBy: PropTypes.string,
        changeDate: PropTypes.string,
      })
    ),
  }),
};

const MyPlan = () => {
  const [planInfo, setPlanInfo] = useState(null);
  const [planHistory, setPlanHistory] = useState(null);

  useEffect(() => {
    const fetchPlanInfo = async () => {
      try {
        const response = await urlService.getPlanUsage();
        setPlanInfo(response);
      } catch (error) {
        toast.error("Failed to fetch plan information");
      }
    };

    const fetchPlanHistory = async () => {
      try {
        const response = await urlService.getPlanHistory();
        setPlanHistory(response);
      } catch (error) {
        toast.error("Failed to fetch plan history");
      }
    };

    fetchPlanInfo();
    fetchPlanHistory();
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 p-8">
      <div className="max-w-6xl mx-auto">
        {planInfo && (
          <PlanDetails planInfo={planInfo} planHistory={planHistory} />
        )}
      </div>
    </div>
  );
};

export default MyPlan;

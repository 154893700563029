import React, { useState } from "react";
import { Plus, X, Link as LinkIcon } from "lucide-react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { urlService } from "../services/urlService";

const FloatingUrlButton = ({ onUrlCreated }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    longUrl: "",
    customAlias: "",
    customDomain: "https://shrtly.net",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await urlService.createShortUrl(formData);
      toast.success("URL shortened successfully!");
      setShowModal(false);
      setFormData({
        longUrl: "",
        customAlias: "",
        customDomain: "https://shrtly.net",
      });
      if (onUrlCreated) onUrlCreated();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Floating Action Button */}
      <button
        onClick={() => setShowModal(true)}
        className="fixed right-8 bottom-8 w-14 h-14 bg-cyan-600 hover:bg-cyan-700 rounded-full shadow-lg flex items-center justify-center text-white transition-colors z-20"
      >
        <Plus size={24} />
      </button>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-30">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full">
            {/* Header */}
            <div className="flex items-center justify-between p-4 border-b">
              <div className="flex items-center gap-2">
                <LinkIcon className="text-cyan-600" size={20} />
                <h3 className="text-lg font-semibold text-gray-900">
                  Shorten New URL
                </h3>
              </div>
              <button
                onClick={() => setShowModal(false)}
                className="text-gray-400 hover:text-gray-500 transition-colors"
              >
                <X size={20} />
              </button>
            </div>

            {/* Form */}
            <form onSubmit={handleSubmit} className="p-4 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Long URL
                </label>
                <input
                  type="url"
                  value={formData.longUrl}
                  onChange={(e) =>
                    setFormData({ ...formData, longUrl: e.target.value })
                  }
                  placeholder="https://example.com"
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-cyan-500 focus:border-transparent"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Custom Alias (Optional)
                </label>
                <input
                  type="text"
                  value={formData.customAlias}
                  onChange={(e) =>
                    setFormData({ ...formData, customAlias: e.target.value })
                  }
                  placeholder="my-custom-url"
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-cyan-500 focus:border-transparent"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Domain
                </label>
                <select
                  value={formData.customDomain}
                  onChange={(e) =>
                    setFormData({ ...formData, customDomain: e.target.value })
                  }
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-cyan-500 focus:border-transparent"
                >
                  <option value="https://shrtly.net">shrtly.net</option>
                </select>
              </div>

              <div className="flex items-center gap-4 pt-4">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="flex-1 px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50 transition-colors"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="flex-1 px-4 py-2 bg-cyan-600 text-white rounded-md hover:bg-cyan-700 transition-colors disabled:bg-cyan-300"
                >
                  {loading ? "Creating..." : "Create Short URL"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

FloatingUrlButton.propTypes = {
  onUrlCreated: PropTypes.func,
};

FloatingUrlButton.defaultProps = {
  onUrlCreated: () => {},
};

export default FloatingUrlButton;
